import 'components/autocomplete'
import { Controller } from 'stimulus'; 


export default class extends Controller {
	static values = { url: String }
	static targets = [ "acinput"]

  connect() {
  	let url = this.urlValue
  	let cu1no = $(this.acinputTarget)
    cu1no.typeahead({
      delay: 200,
      minLength: 2,
      source: function(query, cb){
      	
        $.getJSON({
          url: url,
          data: {
            query: query
          },
          success: function(r){
            return cb(r)
          }
        })
      },
      afterSelect: function(item){
      	cu1no.data('result', item)
      },
    })
  }

  disable_enter(e){
  	if(e.keyCode == 13){
  		event.preventDefault();
  	  return false;
  	}
  }
}