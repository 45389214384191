import { icons } from "feather-icons";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { name: String, attrs: Object };

  connect() {
    const svgString = icons[this.nameValue].toSvg({
      ...this.attrsValue,
    });

    const svgDocument = new DOMParser().parseFromString(
      svgString,
      "image/svg+xml"
    );
    
    const svgElement = svgDocument.querySelector("svg");
    this.element.parentNode.replaceChild(svgElement, this.element);
  }
}