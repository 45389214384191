import { Controller } from 'stimulus'; 

export default class extends Controller {
	static values = { billingUrl: String }
	static targets = [ "acinput", "orderList", "startDate", "endDate", "payload", 'orderCheckbox']

  connect() {
  	this.render_bookkeeping()
  }

  trigger_search(e){
  	if(e.keyCode == 13){
  		this.fetch_customer_billing(e)
  		event.preventDefault()
  	  return false;
  	}	
  }

  add_to_bookkeep(e){
  	e.preventDefault()
  	
  	let subpane = $(e.target).parents('.subpane')
  	let tab_pane = $(e.target).parents('.tab-pane')
  	let selected_option = tab_pane.find('select option:selected')
  	let select = tab_pane.find('select')
  	let active_tab_pane = tab_pane.find('.tab-pane.show.active')
  	let price = active_tab_pane.find('input.price')
  	let json = []

  	try{
  		json = JSON.parse($('#daily_report_payload').val())
  	}catch(err){
  	}

  	let result = {
  		kind: selected_option.data('target'),
  		category: select.data('category'),
  		name: selected_option.text(),
  		price: price.val(),
  		note: ''
  	}

  	active_tab_pane.find('input[data-behavior="saveto"]').each(function(){
  		result[$(this).data('target')] = $(this).val()
  		let a = $(this).closest('.row.form-group')
  		if($(this).data('target') != 'price' && $(this).data('target') != 'note' && $(this).data('target') != 'name'){
  			result['note'] = result['note'] + $(this).closest('.form-group').find('label').html() + ': ' + $(this).val() + ' '
  		}
  	})

  	json.push(result)


  	$('#daily_report_payload').val(JSON.stringify(json))
  	subpane.find('input[type=text]').val('')
  	this.render_bookkeeping()
  }

  check_all_orders(e){
  	this.orderCheckboxTargets.forEach(function(checkbox, index){
  		checkbox.checked = e.target.checked
  	})
  	this.calculate_billing()
  }

  change_tab(e){
  	let select = $(e.target)
  	let option = select.find("option:selected")
  	let target = option.data('target')
  	if(target){
  		$('a[data-toggle="tab"].' + target).tab('show')
  	}
  }

  fetch_customer_billing(e){
  	
  	e.preventDefault()
  	let cu1no = $(this.acinputTarget)
  	let url = this.billingUrlValue
  	let order_list = $(this.orderListTarget)
  	let start_date = $(this.startDatetTarget)
  	let end_date = $(this.endDatetTarget)

  	let customer_same = cu1no.data('cu1no') == cu1no.val()

  	if(!cu1no.val())
  		return

  	$.ajax({
  		url: url,
  		data: {
  			cu1no: cu1no.val(),
  			start_date: customer_same ? start_date.val() : '',
  			end_date: customer_same ? end_date.val() : ''
  		},
  		success: function(result){
  			cu1no.data('cu1no', cu1no.val())
  			order_list.html(result)
  		}
  	})
  }

  trigger_add_to_bookkeep(e){
  	if(e.keyCode == 13){
  	  e.preventDefault()
  	  $(e.target).parents('.subpane').find('a[data-behavior="add_to_bookkeep"]').trigger('click')
  	}
  }

  calculate_discount_price(e){
		let percent = parseInt(e.target.value) * 0.01
		let result = null
		let json = []
		try{
			json = JSON.parse(this.payloadTarget.value)
		}catch(err){
		}

		$.each(json, function(i, data){
  		if(data.kind == 'orders'){
  			result = Math.round(percent * data.price)
  		}
  	})
  	$(e.target).closest('.tab-pane').find('#daily_report_discount-percent-price').val(result)
  }

  calculate_billing(){

  	let json = []
  	try{
  		json = JSON.parse(this.payloadTarget.value)
  	}catch(err){
  	}

  	let order_total = 0
  	let sa1nos = []
  	this.orderCheckboxTargets.forEach(function(checkbox, index){
  		let c = $(checkbox)
  		if(checkbox.checked){
	  		let sa1no = c.data('sa1no')
	  		let sa1total = parseInt(c.parents('tr').find("input[name='daily_report[order_attributes][" + sa1no + "][sa1total]']").val())
	  		let in1mon = parseInt(c.parents('tr').find("input[name='daily_report[order_attributes][" + sa1no + "][in1mon]']").val()) || 0
	  		sa1nos.push(sa1no)
	  		order_total += (sa1total - in1mon)
  		}
  	})

  	$.each(json, function(i, data){
  		if(data.kind == 'orders'){
  			json.splice(i, 1)
  		}
  	})
  	json.push({
  		kind: 'orders',
			category: '出貨單',
			name: '應收帳款',
			price: order_total,
			note: sa1nos.join(', '),
			payload: {
				sa1nos: sa1nos
			}
		})

		this.payloadTarget.value = JSON.stringify(json)

  	this.render_bookkeeping()

  }

  remove_row(e){
  	let payload = this.payloadTarget
  	e.preventDefault()
  	let json = JSON.parse(this.payloadTarget.value)
  	json.splice(e.target.parentNode.dataset.index, 1)
  	this.payloadTarget.value = JSON.stringify(json)
  	this.render_bookkeeping()
  }

  render_bookkeeping(){

  	let table = $('table.bookkeeping body')
  	let json = []
  	try{
  		json = JSON.parse(this.payloadTarget.value)
  	}catch(err){
  	}

    $('table.bookkeeping tbody').html('')
    $.each(json, function(i, row){
    	let tr = $('<tr/>')
    	tr.append('<td>' + row.category + '</td>')
			tr.append('<td>' + row.name + '</td>')
			tr.append('<td>' + row.price + '</td>')
			tr.append('<td>' + row.note + '</td>')
			tr.append('<td><a href="#" data-action="click->daily-report-order#remove_row" data-index="' + i + '"><i data-controller="feather-icon" data-feather-icon-name-value="x"></i></a></td>')
    	$('table.bookkeeping tbody').append(tr)
    })
  }
}