import { Controller } from 'stimulus'; 


export default class extends Controller {
	static targets = [ "input"]

  connect() {}

  manipulate_qty(e){
  	let value = parseInt(e.target.value)

  	if(e.keyCode == 38){
  		value += 1
  	}
  	if(e.keyCode == 40 && value != 0){
  		value -= 1
  	}
  	e.target.value = value
  }
}