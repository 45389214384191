/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import '../stylesheets/application'
import '../stylesheets/signin'
import '../stylesheets/dashboard'
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap-table/dist/bootstrap-table.min.js';
import 'bootstrap-table/dist/bootstrap-table.min.css';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"


const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


const feather = require('feather-icons/dist/feather')

document.addEventListener("turbolinks:load", function() {
  feather.replace()  
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="tab"]').tab()
  $('[data-toggle="bootstrap-table"]').bootstrapTable()

})
