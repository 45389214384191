import { Controller } from 'stimulus'; 


export default class extends Controller {
	static targets = [ "sa1noList", "acinput", "sa1input", "visitType", "note", "payload"]

  connect() {}

  reset_sa1no_list(e){
  	if(e.keyCode == 13){
  		$(this.sa1noListTarget).html('')
  		$(this.sa1inputTarget).val('')
  		event.preventDefault()
  	  return false;
  	}
  }

 showSa1noList(e){
 	let sa1no_list = $(this.sa1noListTarget)
 	let result = $(this.acinputTarget).data('result')
 	if(result){
 		$.each(result.sa1nos, function(i, sa1no){
 			sa1no_list.append('<a href="#" class="mr-2" data-behavior="fill-in-input" data-target="daily_report_sa1no" data-action="fillin-input#fillin_with_comma">' + sa1no + '</a>')
 		})	
 	}
 	
 }

 stringify_payload(e){

		let sa1no = this.sa1inputTarget.value
		let visit_type = this.visitTypeTarget.value
		let note = this.noteTarget.value
		let payload = $(this.payloadTarget)
		let json = []

		try{
			json = JSON.parse(payload.val())
		}catch(err){}

		payload.val(JSON.stringify({
			visit_type: visit_type,
			sa1no: sa1no,
			note: note,
		}))

  }

}