import { Controller } from 'stimulus'; 


export default class extends Controller {
	static targets = [ "input"]

  connect() {}

  fillin_with_comma(e){
  	e.preventDefault()
  	let target = $(this.inputTarget)
  	let input = $(e.target)

		if(target.val()){
      target.val( target.val() + ', ' + input.text())
		}else{
      target.val(input.text())
    }		
  }
}